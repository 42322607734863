<template>
  <MembersLayoutSlot v-if="hasMainLayoutInitialized" :search="keyword">
    <template #header-content>
      <!-- what is this structure? -->
      <SearchInputComponent @on-search="onSearch" :noHide="true"/>
    </template>
  </MembersLayoutSlot>
</template>
<script>
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';

import SearchInputComponent from '@/core/components/common/header/SearchInputComponent.vue';
import MembersLayoutSlot from '@/core/components/members/MembersLayoutSlot.vue';
import { USERS_STORE } from '@/store/modules/users';

export default defineComponent({
  components: {
    SearchInputComponent,
    MembersLayoutSlot
  },

  data() {
    return {
      keyword: ''
    };
  },

  watch: {
    keyword: {
      immediate: true,
      deep: true,
      handler(value) {
        this.keyword = value;
      }
    }
  },

  computed: {
    ...mapGetters(USERS_STORE, ['hasMainLayoutInitialized']),
  },

  methods: {
    onSearch(value) {
      const { search } = value;

      this.keyword = search;
    }
  }
});
</script>
<style lang="scss" scoped>
</style>
