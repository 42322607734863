<template>
    <div class="member-card flex-column jc-sb">
      <section class="member-card__wrapper flex-row ai-fs js-sb">
        <div class="member-card__wrapper flex-row ai-c js-fs">
          <UserThumbnail
              borderRadius="100%"
              width="60px"
              height="60px"
              :key="require(`@/assets/images/box-empty.jpeg`)"
              :source="require(`@/assets/images/box-empty.jpeg`)"
              >
          </UserThumbnail>
          <div class="member-card__text-wrapper flex-column">
            <!-- <p class="member-card__text bold default-text">sample</p>
            <p class="member-card__text default-text">sample</p> -->
          </div>
        </div>
        <div class="flex-row ai-c jc-c cursor-pointer" @click="onDeleteMember(member?.id)">
          <inline-svg width="25" height="25" :src="require('@/assets/icons/delete-icon.svg')"/>
        </div>
      </section>
      <section class="member-card__text-wrapper flex-column ai-fs">
        <p class="member-card__text bold default-text">Email</p>
        <p class="member-card__text default-text">{{ member.email }}</p>
      </section>
      <el-divider/>
      <section class="member-card__text-wrapper flex-column ai-fs">
        <p class="member-card__text bold default-text">Role</p>
        <el-select class="member-card__select" v-model="value" filterable placeholder="Role">
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
            />
        </el-select>
      </section>
    </div>
</template>
<script>
import { defineComponent } from 'vue';

import UserThumbnail from '@/core/components/ui/UserThumbnail.vue';
import permissionConstant from '@/core/constants/permissions';

export default defineComponent({
  components: {
    UserThumbnail
  },
  emits: ['on-delete-member'],
  props: ['member'],
  data() {
    return {
      value: null,
      permissionConstant,
      options: [
        {
          value: permissionConstant.ADMIN_VALUE,
          label: permissionConstant.ADMIN_LABEL,
        },
        {
          value: permissionConstant.EDIT_VALUE,
          label: permissionConstant.EDIT_LABEL,
        },
        {
          value: permissionConstant.VIEW_VALUE,
          label: permissionConstant.VIEW_LABEL,
        },
      ],
    };
  },

  created() {
    this.value = this.member.role;
  },

  methods: {
    onDeleteMember(id) {
      this.$emit('on-delete-member', id);
    }
  }
});
</script>
<style lang="scss" scoped>
.member-card {
  border-radius: 16px;
  box-shadow: 0px 0px 20px 0px rgba(12, 15, 74, 0.10);
  background: #FFF;
  padding: 1rem 1.3rem;
  box-sizing: border-box;
  //   max-width: 280px;
  //   max-height: 286px;

  &__wrapper {
    width: 100%;
  }
  &__text-wrapper {
    width: 100%;
    gap: 0;
  }
  &__text {
    font-size: 14px;
    font-weight: 500;
    line-height: 25px;
    letter-spacing: 0.25px;
  }
  &__text.bold {
    font-weight: 700;
  }
  &__select {
    width: 100%;

    :deep(.el-input__inner) {
      border: none;
      outline: none;
      background: transparent;
      padding: 0;
      width: 100%;
    }
  }
}
.el-divider {
  margin: 0;
}
</style>
