<template>
    <div class="members-layout flex-column">
        <section class="members-layout__header flex-column">
          <div class="members-layout__wrapper flex-row ai-c jc-sb">
            <slot name="header-content"></slot>
            <DisplayViewComponent :viewType="viewType" :disableTileView="true" @set-view="setView" :showAddButton="showAddBtn" @add-function="setInviteMembersModal(true)"/>
          </div>
          <el-divider class="show-in-mobile" style="margin: 0;"/>
          <div class="members-layout__wrapper flex-row ai-c jc-sb">
            <DisplayTypeComponentMobile :viewType="viewType" @set-view="setView"/>
          </div>
        </section>
        <section class="members-layout__content" v-if="membersList">
          <div class="members-layout__wrapper" v-if="membersList.length">
            <template v-if="viewType === displayTypeConstant.LIST">
              <MembersListComponent :membersList="membersList" @show-delete-modal="onDeleteMember($event)" @update-member="onUpdateMember($event)"/>
            </template>
            <template v-if="viewType === displayTypeConstant.TILE">
              <el-scrollbar>
                <div class="members-layout__grid grid">
                  <MemberCard v-for="(member, index) in membersList" :key="index" :member="member" @on-delete-member="onDeleteMember($event)"/>
                </div>
              </el-scrollbar>
            </template>
          </div>
          <EmptyState v-if="!membersList.length" message="No members" icon="no-members-icon.svg"/>
        </section>

        <!-- MODAL -->
        <ConfirmDeleteMemberModal :show="showConfirmDeleteModal" @on-confirm="deleteMember" @on-cancel="onCancelDelete"/>
        <InviteMembersModal :hasUpdated="hasUpdated" :show="inviteMembersModal" @invite="onInvite" @on-close="setInviteMembersModal(false)"/>
    </div>
</template>
<script>
import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';

import MembersListComponent from '@/core/components/common/list/MembersListComponent.vue';
import InviteMembersModal from '@/core/components/common/modals/InviteMembersModal.vue';
import EmptyState from '@/core/components/ui/EmptyState.vue';
import MemberCard from '@/core/components/ui/MemberCard.vue';
import displayTypeConstant from '@/core/constants/display';
import UserService from '@/core/services/users.service';
import DisplayTypeComponentMobile from '@/modules/project-search/components/DisplayTypeComponentMobile.vue';
import DisplayViewComponent from '@/modules/project-search/components/DisplayViewComponent.vue';
import ConfirmDeleteMemberModal from '@/modules/property-details-children/components/modal/ConfirmDeleteMemberModal.vue';
import { USER_WORKSTATION_MEMBERS_STORE } from '@/store/modules/user-workstation-member';
import { USERS_STORE } from '@/store/modules/users';

export default defineComponent({
  components: {
    MembersListComponent,
    DisplayTypeComponentMobile,
    DisplayViewComponent,
    ConfirmDeleteMemberModal,
    EmptyState,
    MemberCard,
    InviteMembersModal
  },

  props: ['search'],

  data() {
    return {
      displayTypeConstant,
      viewType: '',
      selelectedMemberId: null,
      showConfirmDeleteModal: false,
      windowWidth: window.innerWidth,

      membersList: [],

      keyword: '',
      hasUpdated: false
    };
  },
  created() {
    this.initialize();
  },

  watch: {
    windowWidth(newWidth) {
      this.windowWidth = newWidth;
      this.checkWidth();
    },

    search: {
      immediate: true,
      deep: true,
      handler(value) {
        this.keyword = value;
        this.loadMembers();
      }
    }
  },

  computed: {
    ...mapGetters(USER_WORKSTATION_MEMBERS_STORE, ['inviteMembersModal']),
    ...mapGetters(USERS_STORE, ['user', 'wsRole', 'isWsViewer']),

    showAddBtn() {
      const { wsRole, isWsViewer } = this;
      let show = true;

      if (wsRole && isWsViewer) {
        show = !show;
      }

      return show;
    }
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    });
  },

  methods: {
    ...mapActions(USER_WORKSTATION_MEMBERS_STORE, [
      'setInviteMembersModal',
      'createUserWorkstationMembers',
      'getUserWorkstationMembers',
      'updateUserWorkstationMembers',
      'deleteUserWorkstationMember',
      'setRecentlyAdded'
    ]),

    initialize() {
      this.viewType = this.displayTypeConstant.LIST;
    },
    setView(event) {
      this.viewType = event;
    },
    onDeleteMember(memberId) {
      this.selelectedMemberId = memberId;
      this.showConfirmDeleteModal = true;
    },
    onCancelDelete() {
      this.selelectedMemberId = null;
      this.showConfirmDeleteModal = false;
    },
    deleteMember() {
      const params = {
        userWorkstationMemberId: this.selelectedMemberId,
        userId: this.user.id
      };
      this.deleteUserWorkstationMember(params)
        .catch(() => {
          // ...
        })
        .finally(() => {
          this.loadMembers();
          this.showConfirmDeleteModal = false;
        });
    },
    onUpdateMember(params) {
      params.userId = this.user.id;

      this.updateUserWorkstationMembers(params)
        .catch(() => {
        });
    },
    onResize() {
      this.windowWidth = window.innerWidth;
    },
    checkWidth() {
      if (this.windowWidth < 768) {
        this.viewType = this.displayTypeConstant.TILE;
      }
    },

    async loadMembers() {
      const { id } = this.user;
      const params = {
        userId: this.user.id,
        keyword: this.keyword
      };

      await this.getUserWorkstationMembers(params)
        .then((response) => {
          this.membersList = response;
        })
        .catch(() => {

        });

      // include the host
      await UserService.getUser(id)
        .then((user) => {
          if (this.membersList.length) {
            this.membersList.unshift({ ...user, isHost: true });
          }
        });
    },

    async onInvite(members) {
      const payload = {
        userId: this.user.id,
        members
      };

      await this.createUserWorkstationMembers(payload)
        .then((response) => {
          this.setRecentlyAdded(response);
          this.loadMembers();
        })
        .catch((e) => {
          this.$notify.error({
            title: e?.data?.error,
            message: e?.data?.message
          });

          this.setRecentlyAdded(null);
        })
        .finally(() => {
          this.setInviteMembersModal(false);
          this.hasUpdated = !this.hasUpdated; // switch to trigger
        });
    }
  },
});
</script>
<style lang="scss" scoped>
@use "../../../assets/scss/mixins/media-query" as *;
@use "../../../assets/scss/mixins/" as *;

.members-layout {
  max-width: 1230px;
  margin: 0 20px 0 20px;
  box-sizing: border-box;
  height: 100%;
  gap: 0;

  &__header {}
  &__content {
    position: relative;
    height: calc(100% - 3.8rem);
  }
  &__wrapper {
    width: 100%;
    height: 100%;
  }
  &__grid.grid {
    grid-template-columns: repeat(auto-fill, minmax(280px,1fr));
    grid-gap: 1.3rem;
    padding: 1rem;
    box-sizing: border-box;
  }
}

@include media-md-max-width() {
  .members-layout {
    &__content {
        height: calc(100% - 6.8rem);
    }
  }
}
@include media-sm-max-width() {
  .members-layout {
    gap: .5rem;
  }
}
</style>
